<style lang="scss" scoped>
/deep/ .comp-damagestock {
    margin-top: 6vh !important;
    max-width: 1200px;
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
    .serial-scan-btn {
        position: absolute;
        z-index: 1;
        right: 11px;
        background: #f5f7fa;
        padding: 4px 10px;
    }
}
.assets {
    font-size: 13px;
    border-bottom: 1px dashed #eee;
    padding: 5px 0;
    .fl {
        max-width: 96%;
    }
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
    .asset-title {
        vertical-align: middle;
        line-height: 1.5;
        white-space: normal;
        display: inline-block;
    }
}
</style>

<template>
    <div>
        <el-dialog :title="title" custom-class="c-el-dialog comp-damagestock" :visible.sync="showDialog" 
            :before-close="_close" :close-on-click-modal="false" append-to-body>
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="130px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="15" v-if="false">
                        <el-form-item label="仓库" prop="warehouseId">
                            <el-select class="c-pw100 mrgr5 mrgb5" placeholder="选择出库的仓库" size="medium" filterable
                                clearable v-model="dialogData.warehouseId" disabled>
                                <el-option v-for="ws in allWarehouse" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                            </el-select>
                            <!--<el-button class="mrgl5" type="primary" size="small" plain @click="addWarehouse()">新增仓库</el-button>-->
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="报废资产" prop="items">
                            <el-autocomplete :ref="refAutoComplete" class="c-pw100 mrgb10" v-model="keyword" :trigger-on-focus="false" clearable :fetch-suggestions="querySearchAsync" 
                                placeholder="资产名称/条形码" @select="handleSelect" @keyup.enter.native="execSelect()" @clear="clearAutoComplete">
                                <template slot-scope="{ item }">
                                    <dl class="assets">
                                        <dd class="fl">
                                            <el-image class="asset-image" :src="uploadBaseUrl + item.imageUrl">
                                                <div slot="error">
                                                    <i class="el-icon-picture-outline error-image"></i>
                                                </div>
                                            </el-image>
                                            <span class="asset-title">
                                                <span>{{ mxTranceAssetData(item) }}</span>
                                                <span :class="mxTranceAssetData(item) ? '' : 'mrgl10'">{{ item.value }}</span>
                                                <span class="mrgl5 col_danger" v-if="item.maxCount >= 0 && !$root.isPc">【库存：{{ item.maxCount }}】</span>
                                            </span>
                                        </dd>
                                        <dd class="fr col_danger" v-if="item.maxCount >= 0 && $root.isPc">【库存：{{ item.maxCount }}】</dd>
                                        <div class="clearb"></div>
                                    </dl>
                                </template>
                                <template slot="append" v-if="!$root.isPc"><span @click="openBarcodeHtmlScan()"><i class="el-icon-full-screen"></i></span></template>
                            </el-autocomplete>
                            <el-table :data="dialogData.items" border fit highlight-current-row size="small">
                                <el-table-column label="图片【分类-条形码】资产名称" min-width="160">
                                    <template slot-scope="scope">
                                        <el-image class="asset-image" v-if="scope.row.imageUrl"
                                            :src="uploadBaseUrl + scope.row.imageUrl" 
                                            :preview-src-list="[uploadBaseUrl + scope.row.imageUrl]">
                                            <div slot="error">
                                                <i class="el-icon-picture-outline error-image"></i>
                                            </div>
                                        </el-image>
                                        <span>{{ mxTranceAssetData(scope.row) }}</span>
                                        <span :class="mxTranceAssetData(scope.row) ? '' : 'mrgl10'">{{ scope.row.assetName }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="serialNumbers" label="序列号" width="360">
                                    <template slot="header">
                                        序列号
                                        <el-button class="mrgl10" type="warning" size="mini" @click="openScanCode">批量扫码</el-button>
                                    </template>
                                    <template slot-scope="scope" v-if="scope.row.needSerialNumber">
                                        <!--手机扫码-->
                                        <span class="serial-scan-btn" @click="openSerialHtmlScan(scope.row, scope.$index)" v-if="!$root.isPc"><i class="el-icon-full-screen"></i></span>
                                        <el-select class="c-pw100" v-model="scope.row.serialNumbers" multiple filterable remote
                                            :multiple-limit="scope.row.maxCount"
                                            :remote-method="(v) => { loadOptions(v, scope.row, scope.$index) }"
                                            @change="changeQuantity(scope.$index)"
                                            @remove-tag="(v, index) => { removeTag(v, scope.$index) }"
                                            @keyup.enter.native="(v) => { execSerial(v, scope.row, scope.$index) }"
                                            placeholder="扫码或直接输入序列号" size="small">
                                            <el-option v-for="snItem in scope.row.selectNumbers" :key="snItem.sn" :value="snItem.sn" :label="snItem.sn"></el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="count" label="数量" width="100">
                                    <template slot-scope="scope">
                                        <el-input type="number" v-model="scope.row.count" 
                                            :max="scope.row.maxCount" min="0" :disabled="scope.row.needSerialNumber" size="mini"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" width="50" fixed="right" align="center">
                                    <template slot-scope="scope">
                                        <div @click="_delete(scope.row, scope.$index)"><i class="el-icon-delete"></i></div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="附件" prop="attachments">
                            <multiple-images :imageUrls="dialogData.attachments" :folder="'damagestock'" :multiple="true" @emitImageUrls="setImageUrls(arguments)"></multiple-images>
                            <div class="col_danger">可以上传一些附件等，没有可不传</div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="15">
                        <el-form-item label="负责人" prop="principal">
                            <el-autocomplete class="c-pw100 mrgr5 mrgb5" placeholder="请输入负责人(可从使用者中快速选择)" size="medium" :trigger-on-focus="true"
                                clearable v-model="dialogData.principal" :fetch-suggestions="querySearchPrincipal"></el-autocomplete>
                            <div class="col_warning">负责人可直接选择系统内人员，也可以自行输入</div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="备注">
                            <el-input type="textarea" :rows="3" v-model="dialogData.remark" placeholder="请输入备注"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">取消</el-button>
                <el-button size="small" type="primary" @click="_save()">提交</el-button>
            </span>
        </el-dialog>

        <!--新增仓库-->
        <warehouse :ref="refWarehouse" @refreshData="refreshWarehouse"></warehouse>
        <!--扫码getScanSerials-->
        <scan-serial :ref="refToScanSerial" @scanSerials="setAssetDatas"></scan-serial>
        <!--调用摄像头扫码-->
        <html-scan :ref="refHtmlScan" @success="htmlScanSuccess(arguments)"></html-scan>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funInfomation from "@/api/information"
import * as funStock from "@/api/stock"
import configMixins from "@/mixins/config"
import warehouse from "@/views/admin/warehouse/components/edit"
import MultipleImages from "@/components/ImageMultipleUpload"
import ScanSerial from "./scanSerial"
import HtmlScan from "@/components/HtmlScan"
import { getStockCompany, getStockWarehourse } from "@/utils/storages"
import { Weixin } from '@/utils/wx-jssdk.js'
import * as funCommon from "@/api/common"
export default {
    name: "compDamageStock",
    mixins: [enumConfigs, configMixins],
    components: { warehouse, MultipleImages, ScanSerial, HtmlScan },
    data() {
        return {
            refWarehouse: "refDamageStockToWarehouse",
            refToScanSerial: "refDamageStockToScanSerial",
            refAutoComplete: "refDamageStockToAutoComplete",
            refHtmlScan: "refDamageStockToHtmlScan",
            showDialog: false,
            companyItem: {},
            warehourseItem: {},
            dialogData: {},
            defaultDialogData: {
                warehouseId: "",
                principal: "",
                remark: "",
                items: [],
                attachments: []
            },
            dialogType: "",
            formRefName: "refDamageStock",
            formRules: {
                warehouseId: [{ required: true, message: '请选择仓库', trigger: ['blur', 'change']}],
                items: [{ required: true, validator: (rule, value, callback) => {
                    if (this.dialogData.items && this.dialogData.items.length > 0) {
                        callback()
                    } else {
                        callback(new Error("请添加报废资产"))
                    }
                }, trigger: 'change' }],
                principal: [{ required: true, message: '请选择负责人', trigger: ['blur', 'change'] }]
            },
            defaultProps: {
                value: "id",
                children: "children",
                label: "name",
                expandTrigger: "hover",
                checkStrictly: true,
                emitPath: false
            },
            allWarehouse: [],
            allAssetUser: [],
            keyword: "",
            title: ""
        }
    },
    methods: {
        async open(row) {
            this.dialogData = JSON.parse(JSON.stringify(this.defaultDialogData))
            if (row) {
                this.dialogType = this.createOrUpdate[1]
            } else {
                this.dialogType = this.createOrUpdate[0]
            }
            // 默认仓库
            this.companyItem = getStockCompany()
            this.warehourseItem = getStockWarehourse()
            this.dialogData.companyId = this.companyItem && this.companyItem.id || ""
            this.dialogData.warehouseId = this.warehourseItem && this.warehourseItem.id || ""
            if (this.warehourseItem && this.warehourseItem.id) {
                this.title = `报废【${this.companyItem.name}-${this.warehourseItem.name}】`
            }
            await this.getAllWarehouse()
            await this.getAllAssetUser()
            this.showDialog = true
        },
        async getAllWarehouse() {
            await funInfomation.searchWarehouse().then(res => {
                this.allWarehouse = res.items
            })
        },
        async getAllAssetUser() {
            await funInfomation.searchAssetUser().then(res => {
                this.allAssetUser = res.items
            })
        },
        clearAutoComplete() {
            document.activeElement.blur()
            this.keyword = ""
            this.$refs[this.refAutoComplete].getData("")
            this.$refs[this.refAutoComplete].focus()
        },
        querySearchAsync(queryString, cb) {
            // 查询商品
            var restaurants = []
            if (!queryString) {
                cb(restaurants)
                return false
            }
            var query = {
                categoryId: null,
                companyId: "",
                ownerType: this.valOwnerWarehouse() || null,
                ownerId: this.dialogData.warehouseId || "",
                keywords: queryString,
                maxResultCount: 20
            }
            funStock.getStockSearch(query).then(res => {
                for (var i in res) {
                    var item = res[i]
                    var temp = {}
                    temp.id = item.id
                    temp.value = item.name
                    temp.barCode = item.barCode
                    temp.imageUrl = item.imageUrl || ""
                    temp.needSerialNumber = item.hasSerialNumber
                    temp.category = item.category
                    temp.maxCount = item.stock
                    restaurants.push(temp)
                }
                cb(restaurants)
            }).catch(e => {
                this.$alert('资产加载出错!')
                return false
            })
        },
        execSelect(callback) {
            // 扫码自动识别
            let obj = {}
            let searchDatas = []
            if (this.keyword) {
                var query = {
                    categoryId: null,
                    companyId: "",
                    ownerType: this.valOwnerWarehouse() || null,
                    ownerId: this.dialogData.warehouseId || "",
                    keywords: this.keyword,
                    maxResultCount: 20
                }
                funStock.getStockSearch(query).then(res => {
                    for (var i in res) {
                        var item = res[i]
                        var temp = {}
                        temp.id = item.id
                        temp.value = item.name
                        temp.barCode = item.barCode
                        temp.imageUrl = item.imageUrl || ""
                        temp.needSerialNumber = item.hasSerialNumber
                        temp.category = item.category
                        temp.maxCount = item.stock
                        searchDatas.push(temp)
                    }
                    if (searchDatas.length == 1) {
                        obj = searchDatas[0]
                        this.handleSelect(obj, callback)
                        callback && callback(res[0])
                    } else if (searchDatas.length > 1) {
                        this.warningMsg("资产选项不止一个，请手动选择!")
                        return false
                    } else {
                        this.errorMsg("不存在当前资产信息!")
                        return false
                    }
                }).catch(e => {
                    this.$alert('资产加载出错!')
                    return false
                })
            }
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0)
            }
        },
        handleSelect(item, callback) {
            this.clearAutoComplete()
            if (this.dialogData.warehouseId && item.maxCount === 0) {
                this.warningMsg(`${item.value}，库存不足!`)
                return false
            }
            // 重复资产不用添加
            let asset = this.dialogData.items.find(x => {
                return x.assetId == item.id
            })
            if (asset) {
                if (!callback) {
                    this.warningMsg(`${asset.assetName}，已存在资产列表!`)
                }
                return false
            }
            let temp = {}
            temp.assetId = item.id
            temp.assetName = item.value // item.value
            temp.barCode = item.barCode
            temp.needSerialNumber = item.needSerialNumber
            temp.serialNumbers = item.serialNumbers || []
            temp.lastSerialNumbers = item.serialNumbers || []
            temp.count = temp.needSerialNumber ? temp.serialNumbers.length : 1
            temp.imageUrl = item.imageUrl
            temp.category = item.category
            temp.maxCount = item.maxCount
            temp.selectNumbers = []
            this.dialogData.items.push(temp)
        },
        changeQuantity(index) {
            // 创建条目改变数据，先强制不能删除，只能在移除中操作
            let item = this.dialogData.items[index]
            if (item.serialNumbers.length < item.lastSerialNumbers.length) {
                item.serialNumbers = item.lastSerialNumbers
            }
            let serLen = item.serialNumbers.length
            // 序列号数量超出，则截取
            if (item.maxCount > 0 && serLen > item.maxCount) {
                item.serialNumbers = item.serialNumbers.slice(0, serLen - 1)
                this.warningMsg(item.assetName + "，最大出库数量为：" + item.maxCount)
            }
            item.lastSerialNumbers = item.serialNumbers
            item.count = item.serialNumbers.length
        },
        loadOptions(val, row, index, isEnter) {
            if (!val) {
                return false
            }
            let qrCodeItem = this.parsingQRCode(val)
            val = qrCodeItem.scanCode
            let params = {
                assetId: row.assetId || "",
                ownerType: this.dialogData.warehouseId ? this.valOwnerWarehouse() : null,
                ownerId: this.dialogData.warehouseId || "",
                keywords: val,
                maxResultCount: 20
            }
            funStock.getStockSnSearch(params).then(res => {
                if (!isEnter) {
                    // 非按enter键，为搜索
                    this.dialogData.items[index].selectNumbers = res
                } else {
                    if (res.length <= 0) {
                        // 为了强制更新输入框的值
                        this.dialogData.items[index].serialNumbers = JSON.parse(JSON.stringify(this.dialogData.items[index].serialNumbers))
                        this.changeQuantity(index)
                        this.errorMsg("序列号不存在!")
                        return false
                    }
                    // 按enter键为确定
                    if (this.dialogData.items[index].serialNumbers.indexOf(res[0].sn) === -1) {
                        this.dialogData.items[index].serialNumbers.push(res[0].sn)
                    } else {
                        // 为了强制更新输入框的值
                        this.dialogData.items[index].serialNumbers = JSON.parse(JSON.stringify(this.dialogData.items[index].serialNumbers))
                        this.warningMsg("序列号已存在!")
                    }
                    this.changeQuantity(index)
                }
            })
        },
        execSerial(e, row, index) {
            let val = e.target._value
            let qrCodeItem = this.parsingQRCode(val)
            val = qrCodeItem.scanCode
            this.loadOptions(val, row, index, true)
        },
        removeTag(val, index) {
            // 手动点击x移除时触发
            let item = this.dialogData.items[index]
            item.serialNumbers = item.serialNumbers.filter(x => {
                return x != val
            })
            item.lastSerialNumbers = item.serialNumbers
            item.count = item.serialNumbers.length
        },
        // 负责人
        querySearchPrincipal(queryString, cb) {
            var restaurants = this.allAssetUser.map(x => {
                let temp = {
                    name: x.name,
                    value: x.name
                }
                return temp
            })
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
            cb(results)
        },
        _delete(row, index) {
            this.confirm("确定要删除当前数据吗？").then(() => {
                this.dialogData.items.splice(index, 1)
            })
        },
        // 多图上传成功
        setImageUrls(val) {
            if (val[2]) {
                this.dialogData.attachments = JSON.parse(JSON.stringify(val[2]))
                return false
            }
            if (val[0] >= 0) {
                if (!val[1]) {
                    this.dialogData.attachments.splice(val[0], 1)
                } else {
                    this.dialogData.attachments.splice(val[0], 1, val[1])
                }
            } else {
                this.dialogData.attachments.push(val[1])
            }
        },
        _save() {
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    // 验证上传的数量
                    let warnTemp = undefined
                    for ( let i in this.dialogData.items) {
                        if (this.dialogData.items[i].count <= 0) {
                            warnTemp = this.dialogData.items[i]
                            break
                        }
                    }
                    if (warnTemp && warnTemp.count <= 0) {
                        this.errorMsg(`${warnTemp.assetName}，报废数量必须大于0，否则请删除!`)
                        return false
                    }
                    let commitData = {
                        warehouseId: this.dialogData.warehouseId,
                        principal: this.dialogData.principal,
                        remark: this.dialogData.remark,
                        attachments: this.dialogData.attachments || [],
                        items: this.dialogData.items.map(x => {
                            let temp = { 
                                assetId: x.assetId, 
                                count: x.count, 
                                serialNumbers: x.serialNumbers 
                            }
                            return temp
                        }) || []
                    }
                    window.$common.fullLoading()
                    if (this.dialogType == this.createOrUpdate[0]) {
                        // 创建
                        funStock.damageStock(commitData).then((res) => {
                            this.commonSuccess("操作成功!", res)
                        })
                    }
                } else {
                    return false
                }
            })
        },
        addWarehouse() {
            this.$refs[this.refWarehouse].open()
        },
        async refreshWarehouse(data) {
            await this.getAllWarehouse()
            if (data && data.id) {
                this.dialogData.warehouseId = data.id
                this.$forceUpdate()
            }
        },
        openScanCode() {
            this.$refs[this.refToScanSerial].open({ ownerId: this.dialogData.warehouseId })
        },
        setAssetDatas(datas) {
            // 扫码回传数据
            for (let i in datas) {
                let item = datas[i]
                // 判断当前数据是否在商品中存在
                let assetIndex = this.dialogData.items.findIndex(x => {
                    return x.assetId == item.assetId
                })
                // 存在则先判断资产是否相同
                if (assetIndex != -1) {
                    let tempItem = JSON.parse(JSON.stringify(this.dialogData.items[assetIndex]))
                    if (tempItem.serialNumbers.indexOf(item.serialNumber) == -1) {
                        tempItem.serialNumbers.push(item.serialNumber)
                    } else {
                        this.warningMsg("序列号已存在!")
                    }
                    tempItem.lastSerialNumbers = tempItem.serialNumbers
                    this.dialogData.items.splice(assetIndex, 1, tempItem)
                    this.changeQuantity(assetIndex)
                } else {
                    item.serialNumbers = [item.serialNumber]
                    item.lastSerialNumbers = item.serialNumbers
                    this.dialogData.items.push(item)
                    this.changeQuantity(0)
                }
            }
        },
        async getWechatSign(row) {
            await funCommon.GetWechatTicket().then(res => {
                let jssdkRes = res
				let wxJS = new Weixin()
                wxJS.init({
                    appId: jssdkRes.appId, // 必填，公众号的唯一标识
                    timestamp: jssdkRes.timestamp, // 必填，生成签名的时间戳
                    nonceStr: jssdkRes.nonceStr, // 必填，生成签名的随机串
                    signature: jssdkRes.signature // 必填，签名
                })
                wxJS.scanQRCode(wres => {
                    this.htmlScanSuccess([wres.resultStr, row])
                })
            })
        }, 
        // 打开条形码扫码
        openBarcodeHtmlScan() {
            let browser = window.$util.getBrowser()
            if (browser && browser.wechat) {
                this.getWechatSign()
            } else {
                this.$refs[this.refHtmlScan].open()
            }
        },
        // 打开序列号扫码
        openSerialHtmlScan(row, index) {
            row.rowIndex = index
            let browser = window.$util.getBrowser()
            if (browser && browser.wechat) {
                this.getWechatSign(row)
            } else {
                this.$refs[this.refHtmlScan].open(row)
            }
        },
        // 调用摄像头扫码成功
        htmlScanSuccess(arr) {
            let qrCodeItem = this.parsingQRCode(arr[0])
            let val = qrCodeItem.scanCode || ""
            if (!arr[1]) {
                // 没有第二个参数，则为扫条形码
                let barCode = qrCodeItem.bar_code || ""
                this.keyword = barCode || val
                // 扫条形码时，兼容扫序列号
                if (barCode && val) {
                    this.execSelect((item) => {
                        if (item && item.id) {
                            let rowIndex = this.dialogData.items.findIndex(x => {
                                return x.assetId === item.id
                            })
                            if (rowIndex !== -1) {
                                this.loadOptions(val, this.dialogData.items[rowIndex], rowIndex, true)
                            }
                        }
                    })
                } else {
                    this.execSelect()
                }
            } else {
                // 有第二个参数，则为扫序列号
                this.loadOptions(val, arr[1], arr[1].rowIndex, true)
            }
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        commonSuccess(val, data) {
            let tips = val || "操作成功!"
            this.alert(tips).then(() => {
                this._close()
                window.$common.closeFullLoading()
                this.refreshData(data)
            })
        },
        refreshData(data) {
            this.$emit("refreshData", data)
        }
    }
}
</script>